:root ._display-flex{display:flex;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-row{flex-direction:row;}
:root ._flexGrow-1{flex-grow:1;}
:root ._alignItems-flex-start{align-items:flex-start;}
:root ._gap-1481558183{gap:var(--space-8);}
:root ._justifyContent-441309761{justify-content:space-between;}
:root ._pt-1481558214{padding-top:var(--space-7);}
:root ._pb-1481558214{padding-bottom:var(--space-7);}
:root ._flexShrink-1{flex-shrink:1;}
:root ._alignItems-center{align-items:center;}
:root ._gap-1481558214{gap:var(--space-7);}
:root ._height-10037{height:100%;}
:root ._justifyContent-flex-start{justify-content:flex-start;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._gap-1481558307{gap:var(--space-4);}