:root ._display-flex{display:flex;}
:root ._alignItems-center{align-items:center;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._justifyContent-center{justify-content:center;}
:root ._top-52px{top:52px;}
:root ._gap-12px{gap:12px;}
:root ._pb-40px{padding-bottom:40px;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._width-10037{width:100%;}